import {useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize as crs, mt, onDesktop, pb} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {rpAppLink} from "@pg-mono/rp-routes";

import {ApplicationBox} from "../../../application/components/ApplicationBox";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {ContactBar} from "../../../atoms/contact_bar/ContactBar";
import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {PropertyPlanButton} from "../../../offer/components/gallery_holder/GalleryHolder";
import {OfferDisplayType} from "../../../offer/helpers/OfferDisplayType";
import {getOfferDisplayStatus} from "../../../offer/utils/get_offer_display_status";
import {PropertyListPlanModalContent} from "../../../property_list/PropertyListPlanModalContent";
import {PropertyListPlanModalHeader} from "../../../property_list/PropertyListPlanModalHeader";
import {generateHash} from "../../../utils/generate_hash";
import {IPropertyListProperty} from "../../types/PropertyListProperty";
import {IPropertyPlanModalOffer} from "../../types/PropertyPlanModalOffer";
import {usePlansAbTest} from "../../utils/use_plans_ab_test";
import {PropertyPlanDisplay} from "../PropertyPlanDisplay";

interface IProps {
    isOpen: boolean;
    property: IPropertyListProperty;
    offer: IPropertyPlanModalOffer;
    pageCount: number;
    currentPropertyIndex: number;
    currentPage: number;
    propertiesOnCurrentPage: number;
    pageIsLoading: boolean;
    handleClose: () => void;
    handleNextClick: (index: number) => Promise<void>;
    handlePrevClick: (index: number) => Promise<void>;
    onPlanDownloadClick: () => void;
}

export function PropertyPlanModal(props: IProps) {
    const {
        property,
        offer,
        pageCount,
        pageIsLoading,
        currentPage,
        currentPropertyIndex,
        propertiesOnCurrentPage,
        isOpen,
        handleClose,
        handleNextClick,
        handlePrevClick,
        onPlanDownloadClick
    } = props;

    const storeHash = useMemo(() => generateHash(), []);
    const responsiveLinkTarget = useResponsiveLinkTarget();
    const {abTestVariant} = usePlansAbTest();

    const isForSale = offer.configuration.display_type === OfferDisplayType.FOR_SALE;
    const isLimited = offer.configuration.limited_presentation;

    const isForSaleOverLimit = getOfferDisplayStatus(offer) === "limited";
    const isAutoresponderEnabled = offer.configuration.is_autoresponder_enabled;
    const isClassicView = (isForSale || (isForSaleOverLimit && isAutoresponderEnabled)) && !isLimited;
    const isPropertyForSale = property && property.for_sale;

    const buildLink = (propertyId: number) =>
        rpAppLink.property.detail.base({
            vendorSlug: offer.vendor.slug,
            offerSlug: offer.slug,
            offerId: offer.id,
            propertyId: propertyId
        });

    // TODO: Remove this method - and reimplement download button
    const onPlanButtonClick = () => false;

    return (
        <Modal fullScreen isOpen={isOpen} onModalClose={handleClose} modalTheme="dark">
            <Modal.Header variant="bar" showCloseButton={false}>
                <PropertyListPlanModalHeader
                    property={property}
                    onNextClick={handleNextClick}
                    onPrevClick={handlePrevClick}
                    index={currentPropertyIndex}
                    offer={offer}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    propertiesOnCurrentPage={propertiesOnCurrentPage}
                    pageIsLoading={pageIsLoading}
                    onModalClose={handleClose}
                />
            </Modal.Header>
            <Modal.Content>
                <div css={modalContent}>
                    {!pageIsLoading && (
                        <>
                            {abTestVariant === 0 ? (
                                <PropertyPlanDisplay
                                    vendor={offer.vendor}
                                    property={property}
                                    onPlanDownloadClick={onPlanDownloadClick}
                                    legacyPlan={<PropertyListPlanModalContent property={property} offer={offer} link={buildLink(property.id)} />}
                                />
                            ) : (
                                <PropertyListPlanModalContent property={property} offer={offer} link={buildLink(property.id)} />
                            )}
                        </>
                    )}
                    {((isClassicView && isPropertyForSale) || (!property && isClassicView)) && (
                        <div css={leadFormHolder}>
                            <ApplicationBox
                                propertyId={property.id}
                                storeHash={storeHash}
                                source={ApplicationSource.Property}
                                sourceSection={ApplicationSourceSection.SIDEBAR}
                                vendorId={offer.vendor.id}
                                offerId={offer.id}
                                optionalOfferPhoneData={offer}
                                optionalVendorInfoData={offer.vendor}
                                isAutoresponderEnabled={offer.configuration.is_autoresponder_enabled}
                                sidebarBg="dark"
                            />

                            <div css={asideButtonHolder}>
                                {property?.plan && <PropertyPlanButton onPlanButtonClick={onPlanButtonClick} propertyPlan={property.plan} />}

                                <Button as="a" href={buildLink(property.id)} css={detailsButton} target={responsiveLinkTarget} variant="none_secondary">
                                    Zobacz szczegóły
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                {((isClassicView && isPropertyForSale) || (!property && isClassicView)) && (
                    <ContactBar
                        source={ApplicationSource.Property}
                        salesOffices={offer.sales_offices}
                        vendor={offer.vendor}
                        trackVendor={{id: offer.vendor.id, name: offer.vendor.name, slug: offer.vendor.slug}}
                        trackOffer={{
                            geo_point: {
                                coordinates: offer.geo_point.coordinates
                            },
                            id: offer.id,
                            name: offer.name,
                            type: offer.type,
                            region: {
                                country: offer.region.country,
                                full_name: offer.region.full_name,
                                id: offer.region.id
                            }
                        }}
                        trackProperty={property ? property : undefined}
                        backgroundColor="#20212d"
                        isAutoresponderEnabled={offer.configuration.is_autoresponder_enabled}
                        offer={offer}
                        propertyPrice={property.price}
                    />
                )}
            </Modal.Content>
        </Modal>
    );
}

//  Styles
const modalContent = css`
    display: flex;
    flex-direction: column;
    ${pb(8)}

    ${onDesktop(css`
        flex-direction: row;
        column-gap: ${crs(3)};
        ${pb(3)}
    `)}
`;

const asideButtonHolder = (theme: Theme) => css`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
`;

const detailsButton = (theme: Theme) => css`
    color: #fff;
    ${mt()};

    @media (min-width: ${theme.breakpoints.md}) {
        flex: 0 0 auto;
        ${mt(3)};
    }
`;

const leadFormHolder = (theme: Theme) => css`
    flex-grow: 0;
    flex-shrink: 0;
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: flex;
        flex-direction: column;
        flex-basis: 33rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
`;
